import { GetOrders_MyCommandOutput } from "@amzn/ito-client";
import {
  CreateOrderRequestContent,
  CreateOrderResponseContent,
  GetOrderResponseContent,
  PreCheckoutResponseContent,
} from "common/types";
import { GetSecurityTokenResponseContent } from "common/types/orders-types";
import { ORDERING_CONFIG } from "config";
import { StatusCodes } from "http-status-codes";

import { LegacyBaseService } from "../legacy-base-service";
import { IOrdersService } from "./i-orders-service";

export class ITMPv1OrdersService extends LegacyBaseService implements IOrdersService {
  constructor() {
    super(ORDERING_CONFIG.itmpv1Endpoint);
  }

  public async CreateOrder(request: CreateOrderRequestContent, csrfToken: string): Promise<CreateOrderResponseContent> {
    const response = await this.fetch(`/order/`, {
      method: "POST",
      body: JSON.stringify(request),
      headers: { "X-CSRF-TOKEN": csrfToken },
    });

    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred creating the order.");
    }

    const createOrderResponse = await response.json();
    return createOrderResponse;
  }

  public async GetPreCheckoutInformation(
    itemTaxonomyId: string,
    csrfToken: string
  ): Promise<PreCheckoutResponseContent> {
    if (itemTaxonomyId.startsWith("atn::")) {
      itemTaxonomyId = itemTaxonomyId.substring(5);
    }
    const response = await this.fetch(`/order/precheckout/${itemTaxonomyId}`, { method: "GET" });

    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred while checking if the order can be auto approved.");
    }

    const canBeAutoApprovedResponse = await response.json();
    return canBeAutoApprovedResponse;
  }

  /* istanbul ignore next */
  public async GetOrderLegacy(orderTaxonomyId: string): Promise<GetOrderResponseContent> {
    if (orderTaxonomyId.startsWith("ito::")) {
      orderTaxonomyId = orderTaxonomyId.substring(5);
    }

    const response = await this.fetch(`/orders/${orderTaxonomyId}`, { method: "GET" });

    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred while getting the order.");
    }

    const getOrderResponse = await response.json();
    return getOrderResponse;
  }

  /* istanbul ignore next */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  GetOrders_My(nextToken: string, pageSize: number, orderBy: string): Promise<GetOrders_MyCommandOutput> {
    throw new Error("Method not implemented.");
  }

  public async GetSecurityToken(): Promise<string> {
    const response = await this.fetch(`/order/security-token`, { method: "GET" });

    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred while refreshing the security-token.");
    }

    const getSecurityTokenResponseContent: GetSecurityTokenResponseContent = await response.json();
    return getSecurityTokenResponseContent.csrfToken;
  }
}
