import { RulesEvent } from "@amzn/ito-client";

import { Comment } from "./comments-types";
import { MessageStruct } from "./ui-types";

export const APPROVED = "APPROVED";
export const PENDING_APPROVAL = "PENDING_APPROVAL";
export const COMPLETED = "COMPLETED";
export const CANCELLED = "CANCELLED";
export const DENIED = "DENIED";

export interface GetOrderResponseContent {
  /**
   * Order matched
   */
  order?: OrderLegacy;
}

export interface GetOrdersResponseContent {
  /**
   * Order matched
   */
  orders?: OrderLegacy[];
}

export interface GetSecurityTokenResponseContent {
  /**
   * Token to be send in the header X-CSRF to validate post request in backend API
   */
  csrfToken: string;
}

/**
 * This file is autogenerated by the ITOrdering Spec package https://code.amazon.com/packages/ITOrderingSpec/trees/mainline
 **/

export enum HardwareItemStatus {
  assigned = "assigned",
  issued = "issued",
  packed = "packed",
}

export interface HardwareOrderItem {
  /**
   * Type of the the item (compute, peripheral, etc.)
   */
  class: string;

  /**
   * Category of the item (laptop, monitor, etc
   */
  category: string;

  /**
   * Make of the item (HP, Apple, YubiCo, etc.)
   */
  make: string;

  /**
   * Model of the item (e.g. EliteBook 850)
   */
  model: string;

  /**
   * JSON hash with custom specifications according to the item.
   */
  specs?: { [key: string]: string };

  /**
   * Status of the order
   */
  status: HardwareItemStatus | string;
}

export interface SoftwareOrderItem {
  /**
   * Identifier for the security ticket
   */
  securityTicketID: string;
}

/**
 * Union used to create an OR for the shape of Order Item Details
 */
export type OrderItemDetails = SoftwareOrderDetailsMember | HardwareOrderDetailsMember;

interface SoftwareOrderDetailsMember {
  softwareOrderDetails: SoftwareOrderItem;
}

interface HardwareOrderDetailsMember {
  hardwareOrderDetails: HardwareOrderItem;
}

export interface OrderItemSimplified {
  /**
   * Quantity of the specific item.
   */
  quantity: number;

  /**
   * Object with a SoftwareOrderItem or HardwareOrderItem
   */
  details?: OrderItemDetails | any;
}

export enum OrderStatus {
  approved = "approved",
  cancelled = "cancelled",
  complete = "complete",
  denied = "denied",
  new = "new",
  pendingManagerApproval = "pending manager approval",
  processing = "processing",
}

export interface OrderSimplified {
  /**
   * Unique taxonomy identifier of the order.
   */
  orderId: string;

  /**
   * Username of the requester of the order
   */
  requester: string;

  /**
   * Price of the order
   */
  price: number;

  /**
   * Currency code (ISO-4217)
   */
  currency: string;

  /**
   * Status of the order
   */
  status: OrderStatus | string;

  /**
   * Username of the procurement specialist assigned to the order
   */
  assignee?: string;

  /**
   * Username of the amazonian that has to approve the order (manager, etc.)
   */
  requiredApprover: string;

  /**
   * Map of order items (itemId -> OrderItem)
   */
  items: { [key: string]: OrderItemSimplified };

  /**
   * Building of the requester
   */
  building: string;

  /**
   * Timestamp of the creation of the order
   */
  timestamp: Date;
}

export interface CreateOrderRequestContent {
  /**
   * Binds the payload structure to the body of the HTTP message
   */
  items: { [key: string]: OrderItemSimplified };

  /**
   * Body of the comment
   */
  comment?: string;

  /**
   * Audit log for policy acknowledgement from the user
   */
  acknowledgementAudit?: AcknowledgementAuditEntry[];
}

export interface CreateOrderResponseContent extends ApprovalInformation {
  /**
   * Unique taxonomy identifier of the order created.
   */
  orderId?: string;

  /**
   * Status of the order
   */
  orderStatus?: OrderStatus | string;
}

export enum OrderItemCategory {
  hardware = "hardware",
  software = "software",
}

export interface OrderItem {
  /**
   * Quantity of the specific item.
   */
  quantity: number;

  /**
   * Unique taxonomy identifiers of the assets.
   */
  assetsIds?: string[];

  /**
   * Category of the item
   */
  category: OrderItemCategory | string;

  /**
   * Price of the item
   */
  price: number;

  /**
   * Currency code (ISO-4217)
   */
  currency: string;

  /**
   * Object with a SoftwareOrderItem or HardwareOrderItem
   */
  details?: OrderItemDetails;
}

export interface OrderLegacy {
  /**
   * Unique taxonomy identifier of the order.
   */
  orderId: string;

  /**
   * Username of the requester of the order
   */
  requester: string;

  /**
   * Price of the item
   */
  price: number;

  /**
   * Currency code (ISO-4217)
   */
  currency: string;

  /**
   * Status of the order
   */
  status: OrderStatus | string;

  /**
   * Username of the procurement specialist assigned to the order
   */
  assignee?: string;

  /**
   * Username of the amazonian that has to approve the order (manager, etc.)
   */
  requiredApprover: string;

  /**
   * Map of order items (itemId -> OrderItem)
   */
  items: { [key: string]: OrderItem };

  /**
   * Building of the requester
   */
  building: string;

  /**
   * Timestamp of the creation of the order
   */
  timestamp: Date;

  /**
   * Comments posted in the order
   */
  comments?: Comment[];
}

export interface ApprovalInformation {
  /**
   * Boolean indicating if order can be autoapproved
   */
  canBeAutoApproved: boolean;

  /**
   * Object with the name and login of the required approver
   */
  requiredSTPApprover?: UserReference;

  /**
   * Boolean indicating if order can be autoapproved
   */
  requiredApprovals: RulesEvent[];

  messages: MessageStruct[];
}

export interface PreCheckoutInformation extends ApprovalInformation {}

export interface PreCheckoutRequestContent {
  itemId: string;
}

export interface PreCheckoutResponseContent {
  /**
   * PreCheckout information of the item
   */
  information: PreCheckoutInformation;
}

export interface UserReference {
  /**
   * Name of the required approver
   */
  name: string;

  /**
   * Surname of the required approver
   */
  surname?: string;

  /**
   * Username of the required approver
   */
  username: string;
}

/**
 * Request interface for /orders/directs
 */
export interface GetOrdersMyDirectsRequest {
  /**
   * Filters for the orders
   */
  filters?: Record<string, string[]>;

  /**
   * Next token for result set
   */
  nextToken?: string;

  /**
   * Order by field name
   */
  orderBy?: string;

  /**
   * Page size for the response object
   */
  pageSize?: number;
}

export interface AcknowledgementAuditEntry {
  by: string;
  on: Date;
  policyName: string;
  acknowledged: boolean;
}
