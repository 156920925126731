/* istanbul ignore file */
import { MessageBanner } from "@amzn/stencil-react-components/message-banner";
import { Text } from "@amzn/stencil-react-components/text";
import { MessageStruct } from "common/types";
import React, { FunctionComponent } from "react";

export interface MessagesListProps {
  messages?: MessageStruct[];
}

export const MessagesList: FunctionComponent<MessagesListProps> = (props: MessagesListProps) => {
  if (props.messages && props.messages.length > 0) {
    const listItems = props.messages.map((d) => (
      <MessageBanner key={d.message} type={d.type}>
        <div dangerouslySetInnerHTML={{ __html: d.message }} />
      </MessageBanner>
    ));
    return (
      <Text fontSize="T100">
        <hr />
        {listItems}
      </Text>
    );
  } else {
    return <></>;
  }
};
