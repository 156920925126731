import { RulesEvent } from "@amzn/ito-client";
import { Text } from "@amzn/stencil-react-components/text";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent } from "react";

export interface ApprovalsListForModalProps {
  requiredApprovals: RulesEvent[];
}

export const ApprovalsListForModal: FunctionComponent<ApprovalsListForModalProps> = ({
  requiredApprovals,
}: ApprovalsListForModalProps) => {
  /* istanbul ignore next */
  if (requiredApprovals.length === 0) {
    // No approvasl to show
    return <></>;
  }

  // Shows the approvals as list
  const approvalsList = requiredApprovals.map((r) => {
    const params: any = r.params!;
    return (
      <li key={params.templateId}>
        <Text>{params.description}</Text>
      </li>
    );
  });

  return (
    <Text color="neutral90" fontSize="T200">
      <strong>{getString("submitSanctionOrder.approvals.title")}</strong>
      <div>
        <ul>{approvalsList}</ul>
      </div>
    </Text>
  );
};
