import "./style.scss";

import { MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { CreateOrderRequestContent, PreCheckoutInformation } from "common/types";
import { MessageStruct } from "common/types/ui-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import { IOrdersService, OrdersServiceFactory } from "lib/services/orders";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OrderConfirmationState } from "view/Page/OrderConfirmation";

import { ItemStatus } from "../../types/catalogical-type";
import { SubmitSanctionedOrderModal } from "./components/SubmitSanctionedOrderModal";
import { SubmitSecurityOrderModal } from "./components/SubmitSecurityOrderModal";
import { SubmitUnsanctionedOrderModal } from "./components/SubmitUnsanctionedOrderModal";

export interface SubmitOrderModalProps {
  close: () => void;
  taxonomyId: string;
  name: string;
  status: string;
  etaDays: number;
  hasStock: boolean;
  preCheckoutData: PreCheckoutInformation | undefined;
  orderWorkflow: string;
  fetchPreCheckoutData: (token: string, itemTxId: string) => void;
}

const CLOSE_MODAL_TIME = 285000; // 4:45min, token expires at 5min

/**
 * Reusable component to submit an order
 */
export const SubmitOrderModal: FunctionComponent<SubmitOrderModalProps> = ({
  taxonomyId,
  name,
  status,
  etaDays,
  hasStock,
  close,
  preCheckoutData,
  fetchPreCheckoutData,
  orderWorkflow,
}: SubmitOrderModalProps) => {
  const history = useHistory();
  const [csrfToken, setCsrfToken] = useState<string | undefined>(undefined);
  const [csrfTokenIsLoading, setCsrfTokenIsLoading] = useState(true);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [messages, setMessages] = useState<MessageStruct[] | undefined>(undefined);

  const ordersService: IOrdersService = OrdersServiceFactory.getInstance(taxonomyId!);

  useEffect(() => {
    fetchCSRFToken((token: string) => {
      if (!preCheckoutData) {
        fetchPreCheckoutData(token!, taxonomyId);
      }
    });

    const closeModalTimer = setTimeout(() => close(), CLOSE_MODAL_TIME);
    return () => clearTimeout(closeModalTimer);
  }, []);

  useEffect(() => {
    // Append any message comming from ITO
    if (preCheckoutData && preCheckoutData.messages.length > 0) {
      const tempMessages: MessageStruct[] = [];
      if (messages) {
        messages.forEach((m) => tempMessages.push(m));
      }
      preCheckoutData.messages.forEach((m) => {
        tempMessages.push(m);
      });
      setMessages(tempMessages);
    }
  }, [preCheckoutData]);

  const fetchCSRFToken = async (callback: (token: string) => void) => {
    const securityTokenResponse = await ordersService.GetSecurityToken();

    /* istanbul ignore next */
    if (!securityTokenResponse) {
      const tempMessages: MessageStruct[] = [];
      if (messages) {
        messages.forEach((m) => tempMessages.push(m));
      }
      tempMessages.push({
        type: MessageBannerType.Error,
        message: "Couldn't obtain security token to complete the transaction",
      });
      setMessages(tempMessages);
    }
    setCsrfToken(securityTokenResponse);
    setCsrfTokenIsLoading(false);

    callback(securityTokenResponse);

    return securityTokenResponse;
  };

  const submitOrderHandler = async (request: CreateOrderRequestContent) => {
    const buildEtaMessage = () => {
      const daysRange = `${etaDays}-${etaDays + 2}`;
      if (status === ItemStatus.unsanctioned && (!preCheckoutData || preCheckoutData?.canBeAutoApproved === false)) {
        return getString("eta.orderMessage.notAutoApproved", { daysRange });
      }

      if (preCheckoutData?.canBeAutoApproved === true && hasStock) {
        return getString("eta.orderMessage.autoApproved");
      }

      return getString("eta.orderMessage.autoApproved_NoStock", { daysRange });
    };

    // ETA message is added for all type of orders
    let details: any = request.items[taxonomyId!].details;
    if (!details) details = {};
    details.etaMessage = buildEtaMessage();
    request.items[taxonomyId!].details = details;

    try {
      setIsLoadingCreate(true);
      const response = await ordersService.CreateOrder(request, csrfToken!);

      /* istanbul ignore next */
      if (!response.orderId) {
        setIsLoadingCreate(false);
        throw new Error("There was an error while creating the order");
      }
      // Removes the ito:: part of the id
      const orderTaxonomyId = response.orderId.substring(5);
      const state: OrderConfirmationState = {
        ...response,
        canBeAutoApproved: preCheckoutData?.canBeAutoApproved ?? false,
      };
      close();
      history.push({
        pathname: `/confirmation/${orderTaxonomyId}`,
        state,
      });
    } catch (error: any) {
      /* istanbul ignore next */
      {
        setIsLoadingCreate(false);
        const tempMessages: MessageStruct[] = [];
        if (messages) {
          messages.forEach((m) => tempMessages.push(m));
        }
        tempMessages.push(error.message);
        setMessages(tempMessages);
      }
    }
  };

  if (orderWorkflow === "applink-teams") {
    return (
      <SubmitSecurityOrderModal
        close={close}
        taxonomyId={taxonomyId}
        submitOrderHandler={submitOrderHandler}
        preCheckoutInformation={preCheckoutData}
        csrfTokenIsLoading={csrfTokenIsLoading}
        isLoadingCreate={isLoadingCreate}
        messages={messages}
        name={name}
      />
    );
  }

  if (status == ItemStatus.unsanctioned) {
    return (
      <SubmitUnsanctionedOrderModal
        close={close}
        taxonomyId={taxonomyId}
        submitOrderHandler={submitOrderHandler}
        csrfTokenIsLoading={csrfTokenIsLoading}
        isLoadingCreate={isLoadingCreate}
        messages={messages}
      ></SubmitUnsanctionedOrderModal>
    );
  }

  return (
    <SubmitSanctionedOrderModal
      close={close}
      taxonomyId={taxonomyId}
      name={name}
      submitOrderHandler={submitOrderHandler}
      preCheckoutInformation={preCheckoutData}
      csrfTokenIsLoading={csrfTokenIsLoading}
      isLoadingCreate={isLoadingCreate}
      messages={messages}
    ></SubmitSanctionedOrderModal>
  );
};
